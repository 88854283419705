<template>
  <div class="introduction">
    <div>
      <img src="@/assets/icon/icon_host18.png" />
      <span>>数据库上传</span>
    </div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="120px">
        <h2>数据库上传</h2>
        <el-form-item label="数据库名称" :required="true">
          <el-input
            v-model="form.dbName"
            placeholder="请输入字段名称(限30字以内)"
          ></el-input>
        </el-form-item>
        <el-form-item label="数据库简介" :required="true">
          <el-input
            type="textarea"
            v-model="form.introduction"
            placeholder="请输入数据库描述(限300字以内)"
          ></el-input>
        </el-form-item>
        <el-form-item label="数据库路径" :required="true">
          <el-select
            v-model="form.dbUrl"
            placeholder="请选择路径"
            @change="selectsql()"
          >
            <el-option
              class="center_option"
              v-for="(item, key) in options"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          <div class="sub-title">
            温馨提示：此数据库已传至服务器端
          </div>
        </el-form-item>
        <el-form-item label="数据库表" :required="true">
          <el-select v-model="form.datable" placeholder="请选择路径">
            <el-option
              class="center_option"
              v-for="(item, key) in options2"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          <div class="sub-title">
            温馨提示：此数据库已传至服务器端
          </div>
        </el-form-item>
        <el-form-item label="全文路径">
          <el-select v-model="form.fullTextPath" placeholder="请选择全文路径">
            <el-option
              class="center_option"
              v-for="(item, key) in options1"
              :key="key"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          <div class="sub-title">
            温馨提示：全文此时已通过ftp客户端上传主服务器端
          </div>
        </el-form-item>
        <el-form-item label="全文规则">
          <el-input
            v-model="form.fullTextRule"
            placeholder="请输入全文规则"
          ></el-input>
          <div class="sub-title">
            规则说明：路径中所填的值为规则中对应的值
          </div>
        </el-form-item>
        <el-form-item label="数据库类型" :required="true">
          <el-radio v-model="form.retrieveType" label="0">普通型 </el-radio>
          <el-radio v-model="form.retrieveType" label="1">专业型 </el-radio>
        </el-form-item>

        <div class="de"><span>非title选填</span><span>title必填</span></div>

        <el-form-item label="数据库字段" :required="true">
          <el-button
            type="primary"
            size="mini"
            style="margin-left:50px"
            @click="getFieldNames()"
            >获取数据库字段</el-button
          >
        </el-form-item>
        <el-table :data="tableData" style="width: 80%">
          <el-table-column prop="index" label="序号" width="140">
          </el-table-column>
          <el-table-column prop="name" label="字段号" width="140">
          </el-table-column>
          <el-table-column label="字段含义" width="140">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.contc"
                placeholder="字段含义"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="可检索字段" width="140">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.asearch"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="可显示字段" width="140">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.ainfo"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="排序" width="140">
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="sx(scope.row.index)"
                type="text"
                size="small"
              >
                <i class="el-icon-caret-top"></i>
              </el-button>
              <el-button
                @click.native.prevent="jx(scope.row.index)"
                type="text"
                size="small"
              >
                <i class="el-icon-caret-bottom"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="zxr">下载付费设置</div>
        <el-form-item label="数据库包月" :required="true">
          <el-input
            v-model="form.monthlyCharge"
            placeholder="请输入知识币"
          ></el-input>
          <div class="sub-title">
            提示：上传数据库，只需要设置包月；未设置知识币，则默认为免费。
          </div>
        </el-form-item>
        <el-form-item label="单次付费" :required="true">
          <el-input
            v-model="form.onceCharge"
            placeholder="请输入知识币"
          ></el-input>
          <div class="sub-title">
            温馨提示：单次付费：针对单个数据库单个源文件
            包月：针对30天内数据库所有源文件可无限下载
          </div>
        </el-form-item>
        <el-form-item>
          <el-button>取消</el-button>
          <el-button type="primary" @click="onSubmit" style="    float: right;"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {post} from '@/services/http'
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      options: [],
      options1: [],
      options2: [],
      tableData: [],
      resourceNum:0,
      form: {
        dbName: "",
        introduction: "",
        monthlyCharge: "",
        dbUrl: "",
        fullTextPath: "",
        fullTextRule: "",
        retrieveType: "0",
        onceCharge: "",
        datable: "",
      },
    };
  },
  created() {
    this.init();
    this.getSourcePath();
  },
  methods: {
    //获取数据库路径
    async init() {
      const token = localStorage.getItem("Authorization");
      const formData = new FormData();
      let that = this;
      formData.append("dbPath", this.$store.state.userinfo.ftppath);
      await axios
        .post(
          this.api.LoginURL.concat("/common/getDbPath"),
          formData,

          {
            headers: {
              token: ` ${token}`,
            },
          }
        )
        .then((response) => {
          that.options = response.data.data;
          console.log(JSON.parse(JSON.stringify(response.data.data)));
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    async selectsql() {
      const token = localStorage.getItem("Authorization");
      const formData = new FormData();
      let that = this;
      formData.append("dbPath", this.form.dbUrl);
      await axios
        .post(
          this.api.LoginURL.concat("/common/getTableName"),
          formData,

          {
            headers: {
              token: ` ${token}`,
            },
          }
        )
        .then((response) => {
          that.options2 = response.data.data;
          console.log(JSON.parse(JSON.stringify(response.data.data)));
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    async getSourcePath() {
      let that = this;
      const token = localStorage.getItem("Authorization");
      var formData = new FormData();
      formData.append("path",this.$store.state.userinfo.ftppath);
      await axios
        .post(
          this.api.LoginURL.concat("/common/getSourcePath"),

          formData,
          {
            headers: {
              "Content-Type": "application/json",
              token: ` ${token}`,
            },
          }
        )
        .then((response) => {
          that.options1 = response.data.data;
          console.log(response);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },

    //获取数据库字段
    async getFieldNames() {
      if (
        this.form.dbUrl == "" ||
        this.form.dbUrl == "null" ||
        this.form.dbUrl == "undefined"
      ) {
        this.$message.warning("请选择数据库路径!");
      } else {
        const formData = new FormData();
        let that = this;
        formData.append("dbPath", this.form.dbUrl);
        formData.append("table", this.form.datable);
        const token = localStorage.getItem("Authorization");
        await axios.post(this.api.LoginURL.concat("/common/getFieldNames"), formData, {
          headers: {
            "Content-Type": "application/json",
            token: ` ${token}`,
          },
        })
        .then((response) => {
          that.tableData = [];
          for (let i = 0; i < response.data.data.length; i++) {
            that.tableData.push({
              index: i,
              name: response.data.data[i],
              address: "",
              contc: "",
              asearch: true,
              ainfo: true,
            });
          }
        })
        .catch(function(error) {
          console.log("错误" + error);
        });

        //获取资源量
        post('/common/getResourceNum',formData).then(res=>{
          this.resourceNum = res.data
          console.log(this.resourceNum ,456);
        })
      }
    },
    async onSubmit() {
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].ainfo == "true") {
          this.tableData[i].ainfo = "0";
        } else {
          this.tableData[i].ainfo = "1";
        }
        if (this.tableData[i].asearch == "true") {
          this.tableData[i].asearch = "0";
        } else {
          this.tableData[i].asearch = "1";
        }
        let info = await INSERT(
          "POST",
          "",
          'insert_dbFieldInformation(objects: {dbField: "' +
            this.tableData[i].name +
            '", dbFieldMeaning: "' +
            this.tableData[i].contc +
            '", isTitle: 1,dbnam: "' +
            this.form.dbUrl +
            '",dbindex : "' +
            this.tableData[i].index +
            '",isdrive : "' +
            this.tableData[i].ainfo +
            '",isserch : "' +
            this.tableData[i].asearch +
            '"}) {    affected_rows  returning{id}   }'
        );
      }
      if (
        this.form.dbName == "" ||
        this.form.dbName == "null" ||
        this.form.dbName == "undefined"
      ) {
        this.$message.warning("请填写数据库名称!");
      } else if (
        this.form.introduction == "" ||
        this.form.introduction == "null" ||
        this.form.introduction == "undefined"
      ) {
        this.$message.warning("请填写数据库简介!");
      } else if (
        this.form.dbUrl == "" ||
        this.form.dbUrl == "null" ||
        this.form.dbUrl == "undefined"
      ) {
        this.$message.warning("请选择数据库路径!");
      } else if (
        this.form.retrieveType == "" ||
        this.form.retrieveType == "null" ||
        this.form.retrieveType == "undefined"
      ) {
        this.$message.warning("请选择数据库类型!");
      } else if (
        this.form.monthlyCharge == "" ||
        this.form.monthlyCharge == "null" ||
        this.form.monthlyCharge == "undefined"
      ) {
        this.$message.warning("请输入包月价格!");
      } else if (
        this.form.onceCharge == "" ||
        this.form.onceCharge == "null" ||
        this.form.onceCharge == "undefined"
      ) {
        this.$message.warning("请输入单次付费价格!");
      } else {
        let info1 = await INSERT(
          "POST",
          "",
          'insert_dbInformation(objects: {dbName: "' +
            this.form.dbName +
            '", dbProvider : "' +
            this.$store.state.userinfo.name +
            '", chargeType : "' +
            1 +
            '",onceCharge : "' +
            this.form.onceCharge +
            '",monthlyCharge : "' +
            this.form.monthlyCharge +
            '",resourceNum  : "' +
            this.resourceNum +
            '",introduction  : "' +
            this.form.introduction +
            '",state  : "' +
            1 +
            '",dbUrl  : "' +
            this.form.dbUrl +
            '",fullTextPath   : "' +
            this.form.fullTextPath +
            '",fullTextRule  : "' +
            this.form.fullTextRule +
            '",retrieveType : "' +
            this.form.retrieveType +
            '",datable : "' +
            this.form.datable +
            '",userid  : "' +
            this.$store.state.userinfo.id +
            '",username  : "' +
            this.$store.state.userinfo.name +
            '",failmessage  : "' +
            0 +
            '",isFullText  : "' +
            1 +
            '",uploadTime  : "' +
            new Date() +
            '", }) {    affected_rows  returning{id}   }'
        );
        if (info1.data.insert_dbInformation.returning.length > 0) {
          this.$message({
            message: "上传成功",
            type: "success",
            duration: "1000",
          });
          this.$router.push({
            path: "/Knowledge_index",
          });
        }
      }
    },
    sx(scope) {
      this.tableData[scope].index = this.tableData[scope].index - 1;
      let a = this.tableData[scope - 1].index;
      this.tableData[scope - 1].index = a + 1;
      this.tableData.sort((a, b) => Date.parse(a.index) - Date.parse(b.index));
    },
    jx(scope) {
      this.tableData[scope].index = this.tableData[scope].index + 1;
      let a = this.tableData[scope + 1].index;
      this.tableData[scope + 1].index = a - 1;
      this.tableData.sort((a, b) => Date.parse(a.index) - Date.parse(b.index));
    },
  },
};
</script>

<style scoped>
.form {
  background-color: #ffffff;
  margin-top: 20px;
  padding: 0px 0px 50px 0px;
}
.introduction {
  margin: 10px 100px 10px 130px;
}
h2 {
  text-align: center;
  font-size: 25px;
  border-bottom: 2px solid #eeeeee;
  line-height: 70px;
}
.el-form-item {
  margin-bottom: 22px;
  margin-left: 250px;
  margin-right: 250px;
}
.de {
  margin-left: 370px;
  margin-bottom: 22px;
  margin-right: 250px;
}
.de span {
  margin-right: 50px;
}
.sub-title {
  color: #dcdfe6;
  line-height: 25px;
  font-size: 12px;
}
.zxr {
  text-align: center;
  line-height: 60px;
  font-size: 17px;
}
.el-table {
  width: 80%;
  margin-left: 10%;
}
</style>
